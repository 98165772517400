import React, { useEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { graphql, useStaticQuery } from 'gatsby';
import { StringParam, useQueryParam } from 'use-query-params';
import { isBrowser, sendMessageToIframe } from '@/utils/browser';
import { DeviceType } from '@/utils/definitions';
import { PlacementKind } from '@/webapi/use-experience-api';
import {
  CatalogApp,
  CatalogWidgetProps,
} from '@/webapi/use-widget-catalog-api';
import { Env } from '@/webapi/routes';
import { getCountryCode } from '@/utils/country-codes';
import { QBItemSelection } from '@/components/query-builder/models';
import { ChangeEdit } from '@/features/editor/context/use-experience-state';
import { getScreenshotFromChange } from '@/utils/change-screenshot';
import {
  AllMutationKind,
  AutomationStep,
  DeclarativeBlock,
  MoveChange,
  WidgetChange,
} from '@/pkg/sdk';
import { CatalogCheckoutApp } from '@/webapi/checkout-widget-props';

export const STATE_LOCAL_STORAGE_KEY = `loomi_editor_state`;

export interface EditorState {
  device: DeviceType;
  installedFonts: string[];
  mostPopularFont?: string;
}

export function useDevicePreview(
  listener: (BaseMessage) => void,
  latestChangeEdit: ChangeEdit | undefined,
  setQuickPreviewInfo: (info: {
    url?: string;
    placement?: PlacementKind;
    device?: DeviceType;
  }) => void,
  storeAlias: string,
  experienceId: string,
  other?: QBItemSelection[],
): DevicePreviewHook {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stage
        }
      }
    }
  `);
  const { stage } = data.site.siteMetadata;
  const env = stage === Env.STAGING || stage === Env.LOCAL ? 1 : 2;
  const [editorState, setEditorState] = useLocalStorage<EditorState>(
    STATE_LOCAL_STORAGE_KEY,
    { device: DeviceType.Mobile, installedFonts: [] } as EditorState,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setQpDevice] = useQueryParam(`device`, StringParam);

  const [isAnnotations, setIsAnnotations] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const callback = (msg) => {
    try {
      const data = msg.data as ElementMessage;
      if (data && data.action) {
        listener(data);
      }
    } catch (ignored) {
      isBrowser() && window.console.error(`loomi`, ignored);
    }
  };

  useEffect(() => {
    isBrowser() && window.frames.addEventListener(`message`, callback);
    return () => {
      isBrowser() && window.frames.removeEventListener(`message`, callback);
    };
  }, [callback]);

  const sendInitMessage = () => {
    sendMessageToIframe(
      {
        action: `init`,
        storeAlias,
        env,
        experienceId,
        device: editorState.device === DeviceType.Desktop ? `d` : `m`,
        country: getCountryCode(other),
      } as InitMessage,
      iframeRef,
    );
  };

  const anchor = (selector: string, origin?: AnchorOrigin) => {
    sendMessageToIframe(
      {
        action: `anchor`,
        selector,
        origin,
      } as AnchorMessage,
      iframeRef,
    );
  };

  const leaveAnchor = () => {
    sendMessageToIframe(
      {
        action: `exit`,
      } as BaseMessage,
      iframeRef,
    );
  };

  const looseFocus = () => {
    sendMessageToIframe(
      {
        action: `lostFocus`,
      } as BaseMessage,
      iframeRef,
    );
  };

  const pickElement = (selector: string) => {
    sendMessageToIframe(
      {
        action: `pick`,
        selector,
      } as ElementMessage,
      iframeRef,
    );
  };

  const automateIdle = () => {
    sendMessageToIframe(
      {
        action: `automate_idle`,
      } as ElementMessage,
      iframeRef,
    );
  };

  const automateRecord = () => {
    sendMessageToIframe(
      {
        action: `automate_record`,
      } as ElementMessage,
      iframeRef,
    );
  };

  const automatePlay = (steps: AutomationStep[]) => {
    sendMessageToIframe(
      {
        action: `automate_play`,
        steps,
      } as AutomationStepsMessage,
      iframeRef,
    );
  };

  const hover = (selector: string) => {
    sendMessageToIframe(
      {
        action: `hover`,
        selector,
      } as ElementMessage,
      iframeRef,
    );
  };

  const downloadAppWidgets = (app: CatalogApp) => {
    sendMessageToIframe(
      {
        action: `downloadAppWidgets`,
        widgets: app?.widgets?.map(({ id, version }) => ({
          widgetId: id,
          version,
        })),
      } as DownloadAppWidgetsMessage,
      iframeRef,
    );
  };

  const syncChanges = (mutations: Record<string, DeclarativeBlock>) => {
    sendMessageToIframe(
      {
        action: `applyM`,
        mutations,
      } as ApplyMutationsMessage,
      iframeRef,
    );
  };

  const updateWidgetProps = (propsSelector: string, props: any) => {
    sendMessageToIframe(
      {
        action: `updateW`,
        propsSelector,
        props,
      } as UpdateWidgetPropsMessage,
      iframeRef,
    );
  };

  useEffect(() => {
    if (latestChangeEdit) {
      const { path, name } = getScreenshotFromChange(
        storeAlias,
        latestChangeEdit.experienceId,
        latestChangeEdit.variant,
        latestChangeEdit.change,
      );

      sendMessageToIframe(
        {
          action: `screenshot`,
          path,
          name,
          mountSelector:
            latestChangeEdit.change.block.selector === `div`
              ? latestChangeEdit.change.editorSelector
              : latestChangeEdit.change.block.selector,
          changeSelector:
            (latestChangeEdit.change?.block?.value as WidgetChange)?.env?.[
              `sectionId`
            ] ||
            (latestChangeEdit.change?.block?.value as MoveChange)?.destSelector,
        } as CaptureScreenshotMessage,
        iframeRef,
      );
    }
  }, [latestChangeEdit]);

  const setPreviewDevice = (device: DeviceType) => {
    editorState.device = device;
    setEditorState(editorState);
  };

  const setInstalledFonts = (fonts: string[], mostPopularFont?: string) => {
    editorState.installedFonts = fonts;
    editorState.mostPopularFont = mostPopularFont;
    setEditorState(editorState);
  };

  const toggleAnnotations = () => {
    if (isAnnotations) {
      sendMessageToIframe({ action: `hideAnnotations` }, iframeRef);
      setIsAnnotations(false);
    } else {
      sendMessageToIframe({ action: `showAnnotations` }, iframeRef);
      setIsAnnotations(true);
    }
  };

  const syncAutomations = (steps: AutomationStep[]) => {
    sendMessageToIframe(
      {
        action: `syncAutomations`,
        steps,
      } as AutomationStepsMessage,
      iframeRef,
    );
  };

  const sendForceLoadMessage = () => {
    console.log(`vsly-editor`, `sending force load message`);
    sendMessageToIframe(
      {
        action: `forceLoad`,
      } as BaseMessage,
      iframeRef,
    );
  };

  useEffect(() => {
    setQuickPreviewInfo({ device: editorState.device });
  }, [editorState]);

  useEffect(() => {
    if (editorState?.device) {
      setQpDevice(editorState?.device?.toString()?.toLowerCase());
    }

    sendMessageToIframe(
      { action: `reload`, url: iframeRef?.current?.src } as ReloadMessage,
      iframeRef,
    );
  }, [editorState.device, getCountryCode(other)]);

  return {
    iframeRef,
    anchor,
    leaveAnchor,
    looseFocus,
    hover,
    pickElement,
    automateIdle,
    automateRecord,
    automatePlay,
    syncChanges,
    updateWidgetProps,
    downloadAppWidgets,
    editorState,
    setPreviewDevice,
    toggleAnnotations,
    syncAutomations,
    setInstalledFonts,
    sendInitMessage,
    sendForceLoadMessage,
  };
}

export interface DevicePreviewHook {
  iframeRef: React.MutableRefObject<HTMLIFrameElement>;
  anchor: (selector: string, origin?: AnchorOrigin) => void;
  leaveAnchor: () => void;
  looseFocus: () => void;
  hover: (selector: string) => void;
  pickElement: (selector: string) => void;
  automateIdle: () => void;
  automateRecord: () => void;
  automatePlay: (steps: AutomationStep[]) => void;
  syncChanges: (mutations: Record<string, DeclarativeBlock>) => void;
  downloadAppWidgets: (app: CatalogApp) => void;
  updateWidgetProps: (propsSelector: string, props: any) => void;
  editorState: EditorState;
  setPreviewDevice: (device: DeviceType) => void;
  toggleAnnotations: () => void;
  syncAutomations: (steps: AutomationStep[]) => void;
  setInstalledFonts: (fonts: string[], mostPopularFont?: string) => void;
  sendInitMessage: () => void;
  sendForceLoadMessage: () => void;
}

export type LifecycleEventKind =
  | 'loading'
  | 'loaded'
  | 'forceLoad'
  | 'init'
  | 'load_error'
  | 'reload';
export type CrosshairEventKind =
  | 'hover'
  | 'anchor'
  | 'create'
  | 'pick'
  | 'automate_idle'
  | 'automate_record'
  | 'automate_play'
  | 'exit';
export type EditorEventKind =
  | 'deviceChange'
  | 'applyM'
  | 'globalCss'
  | 'globalJs'
  | 'updateW'
  | 'measure'
  | 'lostFocus'
  | 'downloadAppWidgets'
  | 'screenshot'
  | 'openLink';
export type CrosshairContextMenuKind =
  | 'navigateTo'
  | 'visualEdit'
  | 'getVisualEdit'
  | 'codeEdit'
  | 'editExternal'
  | 'removeElement'
  | 'copyElement'
  | 'moveElement'
  | 'newAutomationStep';
export type AnnotationsEventKind =
  | `showAnnotations`
  | `hideAnnotations`
  | `syncAutomations`;
export type CheckoutExtKind = `checkoutNewComponent`;
export type MessageKind =
  | LifecycleEventKind
  | CrosshairEventKind
  | EditorEventKind
  | CrosshairContextMenuKind
  | AnnotationsEventKind
  | CheckoutExtKind;

export interface SelectorAlias {
  before: string;
  after: string;
  afterSelector: string;
  isAliased: boolean;
}
export interface BaseMessage {
  action: MessageKind;
}

export interface OpenLinkMessage extends BaseMessage {
  url: string;
  tabName: string;
}

export interface ReloadMessage extends BaseMessage {
  url?: string;
}

export interface ExternalEditMessage extends BaseMessage {
  widgetId: string;
  loomiId: string;
  experienceName: string;
  experienceId: string;
}

export interface PageLoadErrorMessage extends BaseMessage {
  type: 'ERROR' | 'REDIRECTION';
  originalUrl: string;
  redirectionUrl: string;
}

export interface InitMessage extends BaseMessage {
  env: number;
  storeAlias: string;
  experienceId: string;
  device: string;
  country: string;
}

export interface DeviceChangeMessage extends BaseMessage {
  device: string;
}

export type ExtensionType =
  | ``
  | `shopify-post-purchase`
  | `shopify-checkout-extensibility`;

export interface LoadedMessage extends BaseMessage {
  fonts: string[];
  mostPopularFont?: string;
  extensionName?: ExtensionType;
  mountPointsCount?: number;
  catalog?: CatalogCheckoutApp[];
}

export interface AutomationMessage extends BaseMessage {
  step: AutomationStep;
}

export interface AutomationStepsMessage extends BaseMessage {
  steps: AutomationStep[];
}

export interface AnchorMessage extends BaseMessage {
  selector: string;
  origin: AnchorOrigin;
}

export interface ElementMessage extends BaseMessage {
  selector: string;
  html?: string;
  css?: string;
  js?: string;
  computedStyle?: Record<string, string>;
  widgetId?: string;
  loomiId?: string;
  visualProps?: CatalogWidgetProps;

  isHtmlEditable?: boolean;
}

export interface NewElementMessage extends BaseMessage {
  kind: AllMutationKind;
  selector: string;
  styles?: { display: string; padding: string; margin: string };
  size?: { height: number; width: number };
}

export interface ApplyMutationsMessage extends BaseMessage {
  mutations: Record<string, DeclarativeBlock>;
}

export interface DownloadAppWidgetsMessage extends BaseMessage {
  widgets: Array<{ widgetId: string; version: string }>;
}

export interface MeasurementMessage extends BaseMessage {
  selector: string;
  rect: DOMRect;
  widgetId?: string;
}

export interface CopyElementMessage extends BaseMessage {
  selector: string;
  html?: string;
  widgetId?: string;
  experienceId?: string;
  experienceName?: string;
  loomiId?: string;
}

export interface MoveElementMessage extends BaseMessage {
  selector: string;
  widgetId?: string;
  loomiId?: string;
  origMoveSelector?: string;
}

export interface UpdateWidgetPropsMessage extends BaseMessage {
  propsSelector: string;
  props: any;
}

export interface NavigateToMessage extends BaseMessage {
  url: string;
}

export interface CaptureScreenshotMessage extends BaseMessage {
  path: string;
  name: string;
  mountSelector: string;
  changeSelector?: string;
}

export interface CheckoutNewComponent extends BaseMessage {
  mountPointSelector: string;
  placement: NewElementPlacement;
  sectionId?: string;
  dependsOnSectionId?: string;
}

export type NewElementPlacement = `root` | `before` | `after` | `replace`;

export enum AnchorOrigin {
  CLICK = 0,
  NEW_ELEMENT = 1,
  EXTERNAL = 2,
}
