import { MutableRefObject, useCallback, useContext, useEffect } from 'react';
import {
  DevicePreviewHook,
  LoadedMessage,
} from '@/features/editor/context/use-device-preview';
import { UseDeviceNavigationHook } from '@/features/editor/context/use-device-navigation';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { EditorContext } from '@/features/editor/context/editor-context';

const CHECK_FOR_LONG_LOADING_TIMEOUT = 15 * 1000;

export function useWatchLoadingLoop(
  previewLoadingRef: MutableRefObject<boolean>,
  devicePreview: DevicePreviewHook,
  deviceNavigation: UseDeviceNavigationHook,
  handleLoadedMessage: (message: LoadedMessage) => void,
) {
  const { currentExtension } = useContext(EditorContext);
  const isKilled = useFeatureBit(FeatureBit.KILL_LOADING_LOOP_WATCHER, false);
  let longLoadingTimer = -1;

  const clearTimeouts = () => {
    clearTimeout(longLoadingTimer);
  };

  const isBasicEditorExtension = useCallback(
    () =>
      currentExtension === `` ||
      currentExtension === undefined ||
      currentExtension === null ||
      !deviceNavigation.previewUrl.includes(`sdk.loomi`) ||
      !deviceNavigation.previewUrl.includes(`shopify_checkout_extensibility`),
    [currentExtension, deviceNavigation.previewUrl],
  );

  useEffect(() => {
    if (isKilled || !isBasicEditorExtension()) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    clearTimeouts();
    // @ts-ignore
    longLoadingTimer = setTimeout(
      handleLongLoading,
      CHECK_FOR_LONG_LOADING_TIMEOUT,
    );

    return () => {
      clearTimeouts();
    };
  }, [deviceNavigation.encodedUrl, currentExtension]);

  function handleLongLoading() {
    console.debug(`vsly-editor`, `checking for long loading`);
    if (
      previewLoadingRef?.current === true &&
      devicePreview?.iframeRef?.current
    ) {
      if (!handleForBlockedIframe()) {
        if (!handleBadRedirection()) {
          handleLoadedMessage({} as LoadedMessage);
        }
      }
    }
  }

  function showErrorPage() {
    deviceNavigation && deviceNavigation.navigateToErrorPage();
    handleLoadedMessage({} as LoadedMessage);
  }

  function handleForBlockedIframe() {
    try {
      devicePreview?.iframeRef?.current?.contentWindow?.location?.href;
    } catch (ex) {
      console.error(`vsly-editor`, `error while trying to get iframe url`, ex);
      showErrorPage();
      return true;
    }
    return false;
  }

  function handleBadRedirection() {
    try {
      const currentUrl =
        devicePreview?.iframeRef?.current?.contentWindow?.location?.href;
      if (currentUrl && deviceNavigation?.encodedUrlRef?.current) {
        const url1 = new URL(currentUrl);
        const url2 = new URL(
          deviceNavigation.encodedUrlRef.current,
          document.documentElement.baseURI,
        );
        if (cleanUrl(url1.pathname) !== cleanUrl(url2.pathname)) {
          if (
            url1.pathname.includes(
              `c2hvcGlmeV9jaGVja291dF9leHRlbnNpYmlsaXR5`,
            ) ||
            url2.pathname.includes(`c2hvcGlmeV9jaGVja291dF9leHRlbnNpYmlsaXR5`)
          ) {
            return false;
          }
          console.log(
            `vsly-editor`,
            `quick preview and editor url aren't the same`,
            {
              url1,
              url2,
              currentUrl,
              previewUrl: deviceNavigation.previewUrl,
              encodedUrl: deviceNavigation.encodedUrlRef.current,
            },
          );
          showErrorPage();
          return true;
        }
      }
    } catch (ex) {
      // when error is thrown its most likely due to redirect
      // to an off-proxy url which is not allowed
      console.error(`vsly-editor`, `error while trying to get iframe url`, ex);
      showErrorPage();
      return true;
    }
    return false;
  }
}

function cleanUrl(url: string): string {
  return url.replace(/\/|\?.*$/, ``)?.trim();
}
